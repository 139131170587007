body{
  /* background-color: #404040; */
  background-color: #fafafa;
  /* background-color: #e9e9e9; */
  zoom: 0.85;
}
.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #2e2f30 inset !important;
}
.h11{
  /* color: white; */
}

h1.title {
  /* color: white; */
  font-size: 4rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  margin-top: 0px;
  margin-bottom: 47px;
}
.App{
  overflow: hidden;
}
.flex{
  display: flex;
    align-items: center;
}
.css-1arijsr-MuiSnackbar-root,.noti {
  top: 110px !important;
}

.logo{
  background-color: #bcedf5;
    padding: 4px 15px;
    border-radius: 50%;
    font-size: 2rem;
    padding-bottom: 6px;
}
.logo2{
  width: 100%;
  padding: 10px 0;
  max-width: 150px;
}
@media (max-width: 790px){
  .css-1lvtzne {
      align-content: center;
      align-items: center;
  }
  .logo2{
    margin-left: 0px;
    margin-right: 0;
    width: 48% !important;
  }
  .flexForm > div{
    width: 100% !important;
  }
  .flexForm {
    flex-direction: column !important;
  }
  .css-1orzy9s {
    max-width: 130px;
}
.logos{
  margin: 0 !important;
}
}
.css-r6ewbb-MuiToolbar-root{
  justify-content: space-between !important;
  align-items: flex-end !important;
}
.css-1lvtzne {
  flex-grow: 0 !important;
}
.css-1ytlwq5-MuiAlert-icon {
  font-size: 30px;
}

.blue{
  background-color: #0092a8 !important;
}

.css-124ygl0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: #0092a8;
}
.tool{
  justify-content: space-between;
}

.subNav {
  width: 80%;
  margin: auto;
  padding: 20px;
}
.subNav > button {
  border-bottom: 1px solid;
    padding: 3px;
  font-weight: 600;
}
.navlnk{
  color: black !important;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: bold !important;
}

.subNav input {
color: white;
}
.subNav .svg {
fill: white;
}
.subNav .tool{
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
.flexForm{
  display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}
.flexForm > div{
 width: 45%;
}
.infop{
  color: black;
  font-size: 1.6rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  font-family: sans-serif;
}
.tool2{
  width: 100%;
  margin-bottom: 10px;
}

/* not found page */
/* not found page */


@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&family=Roboto:wght@100;300&display=swap");
:root {
  --button: #b3b3b3;
  --button-color: #0a0a0a;
  --shadow: #000;
  --bg: #737373;
  --header: #7a7a7a;
  --color: #fafafa;
  --lit-header: #e6e6e6;
  --speed: 2s;
}

@property --swing-x {
  initial-value: 0;
  inherits: false;
  syntax: '<integer>';
}
@property --swing-y {
  initial-value: 0;
  inherits: false;
  syntax: '<integer>';
}
.notF {
  min-height: 100vh;
  display: flex;
  font-family: 'Roboto', sans-serif;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--bg);
  color: var(--color);
  perspective: 1200px;
}
.notF a {
  text-transform: uppercase;
  text-decoration: none;
  background: var(--button);
  color: var(--button-color);
  padding: 1rem 4rem;
  border-radius: 4rem;
  font-size: 0.875rem;
  letter-spacing: 0.05rem;
}
.notF p {
  font-weight: 100;
}
.notF h1 {
  -webkit-animation: swing var(--speed) infinite alternate ease-in-out;
          animation: swing var(--speed) infinite alternate ease-in-out;
  font-size: clamp(5rem, 40vmin, 20rem);
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  margin-bottom: 1rem;
  letter-spacing: 1rem;
  transform: translate3d(0, 0, 0vmin);
  --x: calc(50% + (var(--swing-x) * 0.5) * 1%);
  background: radial-gradient(var(--lit-header), var(--header) 45%) var(--x) 100%/200% 200%;
  -webkit-background-clip: text;
  color: transparent;
}
.notF h1:after {
  -webkit-animation: swing var(--speed) infinite alternate ease-in-out;
          animation: swing var(--speed) infinite alternate ease-in-out;
  content: "404";
  position: absolute;
  top: 0;
  left: 0;
  color: var(--shadow);
  filter: blur(1.5vmin);
  transform: scale(1.05) translate3d(0, 12%, -10vmin) translate(calc((var(--swing-x, 0) * 0.05) * 1%), calc((var(--swing-y) * 0.05) * 1%));
}
.notF .cloak {
  animation: swing var(--speed) infinite alternate-reverse ease-in-out;
  height: 100%;
  width: 100%;
  transform-origin: 50% 30%;
  transform: rotate(calc(var(--swing-x) * -0.25deg));
  background: radial-gradient(40% 40% at 50% 42%, transparent, #000 35%);
}
.cloak__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.notF .cloak__container {
  height: 250vmax;
  width: 250vmax;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notF .info {
  text-align: center;
  line-height: 1.5;
  max-width: clamp(16rem, 90vmin, 25rem);
  color: white;
  position: relative;
}
.notF .info > p {
  margin-bottom: 3rem;
}
@-webkit-keyframes swing {
  0% {
    --swing-x: -100;
    --swing-y: -100;
  }
  50% {
    --swing-y: 0;
  }
  100% {
    --swing-y: -100;
    --swing-x: 100;
  }
}
@keyframes swing {
  0% {
    --swing-x: -100;
    --swing-y: -100;
  }
  50% {
    --swing-y: 0;
  }
  100% {
    --swing-y: -100;
    --swing-x: 100;
  }
}

.css-1orzy9s ,.css-1orzy9s{
  width: auto !important;
}
/* not found page */


footer {
  position: fixed;
    bottom: 0;
    /* top: 0; */
    right: 0;
    top: auto;
    left: auto;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    background: #0092a8;
    font-size: 1rem;
    margin-top: 95px;

}
.footer{
  margin-top: 95px;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: none;
    scrollbar-color: #0092a8 rgb(0 146 168 / 0%);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 7px;
  }

  *::-webkit-scrollbar-track {
    background: rgb(0 146 168 / 0%);
  }

  *::-webkit-scrollbar-thumb {
    background-color: #0092a8;
    border-radius: 14px;
    border: 5px solid rgb(0 146 168 / 0%);
  }
  .logos{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 50px;
  }

  .f-link{
    text-decoration: none;
    /* color: #007aed; */
    color: black;
    font-family: monospace;
    font-weight: bold;
    font-size: 1.1rem;
  }

  .con{
    width: 95%;
    margin: auto;
  }
  .con .imgL{
    height: 12vh;
    width: 100%;

  }

  .ligne {  display: grid;
    /* background-image: url("http://localhost:3000/ligne.png"); */
    position: relative;
    grid-template-columns: 0.6fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.6fr;
    grid-template-rows: 2fr 2fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    height: 24vh;
    width: 100%;
    grid-template-areas:
    
      "h1 d1 d2 d3 d4 d5 d6 d7 d8 d9 h"
      "h1 d10 d11 d12 d13 d14 d15 d16 d17 d18 h";
  }

  .grid-d{
    height: 12vh;
    position: relative;
    display: flex;
    align-content: center;
    align-items: center; 
    justify-content: center; }

    .h,.h1{
      height: 24vh;
    }
    .h   .imgL,.h1   .imgL{
      height: 24vh;
    }
  
  .h { grid-area: h; }
  
  .h1 { grid-area: h1; }
  
  .d1 { grid-area: d1; }
  
  .d2 { grid-area: d2; }
  
  .d3 { grid-area: d3; }
  
  .d4 { grid-area: d4; }
  
  .d5 { grid-area: d5; }
  
  .d6 { grid-area: d6; }
  
  .d7 { grid-area: d7; }
  
  .d8 { grid-area: d8; }
  
  .d9 { grid-area: d9; }
  
  .d10 { grid-area: d10; }
  
  .d11 { grid-area: d11; }
  
  .d12 { grid-area: d12; }
  
  .d13 { grid-area: d13; }
  
  .d14 { grid-area: d14; }
  
  .d15 { grid-area: d15; }
  
  .d16 { grid-area: d16; }
  
  .d17 { grid-area: d17; }
  
  .d18 { grid-area: d18; }
  .chh{
    position: absolute;
    padding: 0px 15px;
    color: white;
    font-size: 1.2rem;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .textt{
    position: absolute;
    /* padding: 0px 15px; */
    color: white;
    font-size: 1.2rem;
    width: 50%;
    text-align: center;
    width: 50%;
    overflow: hidden;

  }
  .t1{
    top: 0;
    right: 0;
  }
  .t2{
    top: 0;
    left: 0;
  }
  .t3{
    bottom: 0;
    right: 0;
  }
  .t4{
    bottom: 0;
    left: 0;
  }

  .st{
    width: 200px;
  }

.contt{
  display: flex;
  gap: 40px;
  align-items: center;
  height: 200px;
  /* transform: scaleY(0.7); */
}
.contt2{
  display: flex;
  width: 20%;
  /* flex-direction: column; */
  align-content: center;
  align-items: center;
  justify-content: center;
}

.machine-name{
  margin: 0;
  font-size: 2.5rem;
  /* color: white; */
}
.qrScanner{
  position: relative;
}
.qrScanner img{
  width: 350px;
}
.qrScanner h5{
  position: absolute;
  top: 175px;
  left: 74px;
  font-size: 2rem;
  margin: 0;
  color: black;
}

.MuiBox-root.css-nosrnw{
  margin-top: 0px;
}

.vt{
  margin: 0 !important;
}
.barr{
  width: 240px;
  height: 200px;
}

@media (min-width: 900px)
{
  .css-xdmu94-MuiContainer-root {
      max-width: 1050px !important;
  }

}

.qqq{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  row-gap: 30px;
  column-gap: 40px;
  background-color: white ;
  padding: 30px;

}

.qqq > div{
  display: flex ;
  flex-direction: column;

}

.qqq span{
  text-align: center;
  font-size: 30px;
  padding: 9px;
  color: black;
  border: 4px solid black;
  border-bottom: none;
}

.qqq svg {
  width: 100px;
  height: 100px;
}
form{
  width: 100% !important;
}
.ligne-com{
  border: 2px black solid;
  margin-top: 18px;
  padding: 10px;
  border-radius: 5px;
  zoom: 1;
}

.tit{
  margin: 0px 0px;
  font-size: 3rem;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 3px;
  min-width: 130px;
  text-align: left;
  position: relative;
}

main{
  /* background-color: #404040; */
  padding: 35px;
  width: 100% !important;
  border-radius: 20px;
  overflow-y: scroll;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.css-1vooibu-MuiSvgIcon-root{
  width: 36px;
  height: 36px;
}

.css-7e0s21-MuiPaper-root-MuiAppBar-root{
  background-color: #0092a8 !important;
}

.css-1uwgr7b-MuiTypography-root{
  font-weight: bold !important;
  letter-spacing: 0.01938em !important;
    font-size: 1.2rem !important;

}

.MuiContainer-root.MuiContainer-maxWidthXl.css-19r6kue-MuiContainer-root{
  min-height: 110px;
}

.header_title{
  position: absolute;
    left: 44%;
    top: 6px;
    font-size: 2rem;
    font-weight: bold;
    z-index: 999;
    cursor: pointer;
}

/* .MuiToolbar-root.MuiToolbar-regular.tool.css-r6ewbb-MuiToolbar-root{
  margin-top: 25px;
} */

/* .MuiBox-root.css-2uchni{
  display: none;
} */

.MuiBox-root.css-1t6c9ts{
  justify-content: space-around;
}

.tcheck th,.tcheck td{
  text-align: center !important;
  border-right: 1px solid #d3c7c7 !important;
  font-size: 1.2rem !important;
}

.tcheck .th{
  background-color: #c4e1f2;
}

.tcheck .th2{
  border: 3px solid black !important;
}

.tcheck .OK{
  background-color: rgb(9, 148, 9) !important;
}
.tcheck .NOK{
  background-color: rgb(228, 69, 69) !important;
}
.tcheck .--{
  background-color: rgb(90, 90, 231) !important;
}

.tcheck .css-sghohy-MuiButtonBase-root-MuiButton-root{
  padding: 14% 18px !important;
  width: 100%;
    height: 100%;
}
.tcheck .cell{
  padding: 0% !important;
}
.tcheck .cell button{
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 0%;

}

.pa4,.pa3 {
  display: grid;
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  }
  
  .pa4 .tt1,.pa3 .tt1{ grid-area: 1 / 1 / 2 / 2; }
  .pa4 .tt2,.pa3 .tt2{ grid-area: 1 / 2 / 2 / 3; }
  .pa4 .tt3,.pa3 .tt3{ grid-area: 2 / 1 / 3 / 2; }
  .pa4 .tt4,.pa3 .tt4{ grid-area: 2 / 2 / 3 / 3; }

  .pa2 {
    position: absolute;
    display: grid;
    width: 100%;
    text-align: center;
    color: white;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 5px;
    }
    
    .pa2 .tt1 { grid-area: 1 / 1 / 2 / 2; }
    .pa2 .tt2 { grid-area: 1 / 2 / 2 / 3; }

    .pa1 {
      display: grid;
      position: absolute;
      width: 100%;
      text-align: center;
      color: white;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 8px;
      grid-row-gap: 5px;
      }
      
      .pa1  .tt1 { grid-area: 1 / 1 / 2 / 2; 
        width: 80%;
        margin: auto;
      }

      .tit span{
        top: -28px;
        right: 18px;
        position: absolute;
        background: red;
        width: 30px;
        height: 30px;
        border-radius: 50px;
      }

      .tt1,.tt2,.tt3,.tt4{
        cursor: pointer;
      }
      .MuiModal-root  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,.MuiModal-root .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
        color: white !important;
      }

      .ccc1{
        display: flex;
        gap: 10px;
      }

      .ll1{
        max-width: fit-content;
    padding-bottom: 20px;
      }

      #html5qr-code-full-region > div:nth-of-type(1) img { 
        display: none;
       }

       button#html5-qrcode-button-camera-stop {
        background: darkred;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 7px;
        cursor: pointer;
    }

    button#html5-qrcode-button-camera-start{
      background: darkblue;
      color: white;
      padding: 10px;
      border: none;
      border-radius: 7px;
      cursor: pointer;
    }

    .css-1orzy9s {
  
      background-color: #0094aa !important;
 
  }


  @media print {
    header {display: none !important;}
    .subNav{
      display: none !important;
    }
  }